import * as React from "react";
import { useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import Star from "./star";

const GoogleRating = ({ variant, starVariant }) => {
  const { formatMessage } = useIntl();
  const details = useStaticQuery(graphql`
    query {
      googlePlacesDetails {
        rating
        total
      }
    }
  `);

  const rating = details.googlePlacesDetails?.rating;
  const count = details.googlePlacesDetails?.total;

  if (!details.googlePlacesDetails) {
    return null;
  }

  return (
    <a
      href="https://www.google.com/search?sca_esv=d9880e105989b578&rlz=1C5CHFA_enFR1128FR1128&uds=ADvngMjcH0KdF7qGWtwTBrP0nt7dvUrjEY9yXM9xwtUzsnjC6rzLRRU2FOOB6FmjX9d6AykmA_OmHw6X9E_YbTlzar7VQeU5f5Ff6RGDdgi3Q5FeRXzj0yNSoEaeuvNV1PXrx691ceBO&si=ACC90nwjPmqJHrCEt6ewASzksVFQDX8zco_7MgBaIawvaF4-7nAyPOC8Tn8Zax_sWhmCFuRmavURmX3b-zbfAnzgtoX6X9pmmh4GliNA7-i9bpL_3GY39JI%3D&q=Gravel+Up+Avis&sa=X&sqi=2&ved=2ahUKEwjP0ZuvjY6JAxXtVaQEHfeVCjQQ3PALegQIPRAF&biw=1440&bih=811&dpr=2"
      target="_blank"
      className="reviews-rating"
      data-variant={variant}
    >
      <span>
        {count} {formatMessage({ id: "googleReviews" })}
      </span>
      <span className="reviews-rating-container">
        <span className="reviews-rating-stars">
          <span className="reviews-rating-stars-empty" data-color={starVariant}>
            {Array.from({ length: 5 }).map((_, i) => {
              return <Star key={i} />;
            })}
          </span>
          <span
            className="reviews-rating-stars-full"
            data-color={starVariant}
            style={{ width: `${rating * 20}%` }}
          >
            {Array.from({ length: 5 }).map((_, i) => {
              return <Star key={i} />;
            })}
          </span>
        </span>
        <span>{rating}/5</span>
      </span>
    </a>
  );
};

export default GoogleRating;
