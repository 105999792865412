import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import { useCookies } from "react-cookie";
import Close from "./close";
import ButtonLink from "./button-link";

const query = graphql`
  query {
    allContentfulLink(
      filter: {
        metadata: {
          tags: { elemMatch: { name: { eq: "downloadCatalogHeader" } } }
        }
      }
    ) {
      nodes {
        node_locale
        id
        button
        buttonBackgroundColour
        target
        textColour
        title
        link
      }
    }
  }
`;

const Alert = () => {
  const { formatMessage, locale } = useIntl();
  const heading = formatMessage({ id: "alertHeading" });
  const alertEl = React.useRef(null);
  const [cookies, setCookie] = useCookies(["ALERT"]);
  const { allContentfulLink } = useStaticQuery(query);

  const downloadButton = allContentfulLink.nodes.find(
    (item) => item.node_locale === locale
  );

  const closeAlert = () => {
    setCookie("ALERT", "false", { path: "/" });
  };

  if (!cookies.ALERT || cookies.ALERT === "false") {
    return null;
  }

  return (
    <div className="alert">
      <div className="container" ref={alertEl}>
        <button className="alert-close" onClick={closeAlert}>
          <Close />
        </button>
        <div className="alert-text">
          <p dangerouslySetInnerHTML={{ __html: heading }} />
          <p>
            <FormattedMessage id="alertParagraph" />
          </p>
        </div>
        <div className="alert-form">
          {downloadButton && (
            <ButtonLink
              href={downloadButton.link}
              backgroundColor={downloadButton.buttonBackgroundColour}
              textColor={downloadButton.textColour}
              button={downloadButton.button}
              target={downloadButton.target}
            >
              {downloadButton.title}
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default Alert;
