import * as React from "react";

const Star = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path d="M7 0L9.08525 4.72121L14 5.34752L10.374 8.89171L11.3262 14L7 11.4692L2.67376 14L3.626 8.89171L0 5.34752L4.91475 4.72121L7 0Z" />
    </svg>
  );
};

export default Star;
