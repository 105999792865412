import * as React from "react";
import { useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import Close from "./close";
import Link from "./link";
import Logo from "./logo";
import SocialNetworks from "./social-networks";
import Languages from "./languages";
import AlertToggle from "./alert-toggle";
import FullNav from "./full-nav";
import GoogleRating from "./google-rating";

const query = graphql`
  query {
    allContentfulHome {
      nodes {
        node_locale
        burgerMenuText
        burgerMenuTextLink {
          button
          link
          id
          title
          target
          textColour
        }
      }
    }
  }
`;

const Menu = ({ menu, handleClick }) => {
  const { allContentfulHome } = useStaticQuery(query);
  const { locale } = useIntl();

  const { burgerMenuText, burgerMenuTextLink } = allContentfulHome.nodes.find(
    ({ node_locale }) => node_locale === locale
  );

  return (
    <div
      id="menu"
      className="header-menu"
      aria-modal={menu}
      tabIndex={-1}
      role={menu ? "dialog" : null}
      aria-label="Menu"
    >
      <button onClick={handleClick}>
        <Close />
      </button>
      <div className="container">
        <div className="header-intro">
          <div>
            <Link href="/" onClick={handleClick}>
              <Logo />
            </Link>
            <p className="h2">{burgerMenuText}</p>
            <Link
              href={burgerMenuTextLink.link}
              className="link"
              onClick={handleClick}
            >
              {burgerMenuTextLink.title}
            </Link>
            <GoogleRating />
          </div>
        </div>
        <div className="header-full-nav">
          <div>
            <SocialNetworks />
            <Languages handleClick={handleClick} />
          </div>
          <GoogleRating starVariant="black" />
          <AlertToggle handleClick={handleClick} />
          <FullNav handleClick={handleClick} />
        </div>
      </div>
    </div>
  );
};

export default Menu;
