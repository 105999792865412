import * as React from "react";
import Facebook from "./facebook";
import Instagram from "./instagram";

const SocialNetworks = () => (
  <ul // eslint-disable-line
    className="social-networks"
    role="list"
  >
    <li>
      <a
        href="https://www.facebook.com/GravelUp/"
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
      </a>
    </li>
    <li>
      <a
        href="https://www.instagram.com/gravelup.earth/"
        target="_blank"
        rel="noreferrer"
      >
        <Instagram />
      </a>
    </li>
  </ul>
);

export default SocialNetworks;
