import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import Link from "./link";
import MainNav from "./main-nav";

const query = graphql`
  query {
    destinations: allContentfulDestination(
      filter: { partner: { ne: true } }
      sort: { fields: order }
    ) {
      group(field: category___order) {
        fieldValue
        nodes {
          node_locale
          contentful_id
          title
          slug
          inProject
          category {
            title
          }
        }
      }
    }

    allContentfulHome {
      nodes {
        node_locale
        burgerMenuLinks {
          link
          id
          target
          title
        }
      }
    }
  }
`;

const FullNav = ({ handleClick }) => {
  const { destinations, allContentfulHome } = useStaticQuery(query);
  const { locale, formatMessage } = useIntl();
  const isActiveLocale = ({ node_locale }) => node_locale === locale;

  const { burgerMenuLinks } = allContentfulHome.nodes.find(
    (item) => item.node_locale === locale
  );

  return (
    <nav className="navigation" aria-label={formatMessage({ id: "fullNav" })}>
      <ul // eslint-disable-line
        role="list"
      >
        <li className="all-destinations-item">
          <span>
            <FormattedMessage id="ourDestinations" />
          </span>
          <ul // eslint-disable-line
            role="list"
          >
            {destinations.group.map((group) => (
              <li key={group.fieldValue}>
                <p>{group.nodes.find(isActiveLocale).category.title}</p>
                <ul // eslint-disable-line
                  role="list"
                >
                  {group.nodes
                    .filter(isActiveLocale)
                    .map(({ contentful_id: id, title, slug, inProject }) => (
                      <li key={id}>
                        {inProject ? (
                          <span>{title}</span>
                        ) : (
                          <Link
                            href={`/destinations/${slug}/`}
                            onClick={handleClick}
                          >
                            {title}
                          </Link>
                        )}
                      </li>
                    ))}
                </ul>
              </li>
            ))}
          </ul>
        </li>
        <li className="second-nav-item">
          <ul role="list">
            <li className="main-nav-item">
              <MainNav
                className="navigation-main-nav"
                handleClick={handleClick}
              />
            </li>
            {burgerMenuLinks.map((item) => (
              <li key={item.id}>
                <Link
                  href={`${item.link}`}
                  target={item.target === true ? "_blank" : null}
                  onClick={handleClick && handleClick}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default FullNav;
