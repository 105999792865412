import * as React from "react";

const Hamburger = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Menu</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9999 5H0V3H23.9999V5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9999 13H0V11H23.9999V13Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9999 21H0V19H23.9999V21Z"
      fill="currentColor"
    />
  </svg>
);

export default Hamburger;
