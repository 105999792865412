import * as React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import { useLocation } from "@reach/router";
import { useIntl } from "react-intl";

const query = graphql`
  query {
    pages: allSitePage {
      nodes {
        context {
          id
          locale
        }
        path
      }
    }
  }
`;

const Languages = ({ handleClick }) => {
  const { pages } = useStaticQuery(query);
  const { pathname } = useLocation();
  const { locale: activeLocale } = useIntl();

  const changeLocale = (locale) => {
    const page = pages.nodes.find(({ path }) => path === pathname);
    const path = page?.path.replace(`/${activeLocale}`, "");

    if (page?.context.id) {
      const isAlternateLocale = ({ context }) => {
        return context.id === page?.context.id && context.locale === locale;
      };

      const alternatePage = pages.nodes.find(isAlternateLocale);
      return alternatePage.path;
    }

    return `/${locale}${path || "/404/"}`;
  };

  return (
    <ul // eslint-disable-line
      className="languages"
      role="list"
    >
      <li>
        <Link
          to={changeLocale("fr")}
          hrefLang="fr"
          title="Français"
          className={activeLocale === "fr" ? "active" : null}
          onClick={handleClick}
        >
          FR
        </Link>
      </li>
      <li>
        <Link
          to={changeLocale("en")}
          hrefLang="en"
          title="English"
          className={activeLocale === "en" ? "active" : null}
          onClick={handleClick}
        >
          EN
        </Link>
      </li>
    </ul>
  );
};

export default Languages;
