import * as React from "react";
import { useIntl } from "react-intl";
import { graphql, useStaticQuery } from "gatsby";
import Headroom from "react-headroom";
import Link from "./link";
import Logo from "./logo";
import SocialNetworks from "./social-networks";
import AlertToggle from "./alert-toggle";
import Languages from "./languages";
import MainNav from "./main-nav";
import Hamburger from "./hamburger";
import Menu from "./menu";

const query = graphql`
  query {
    allContentfulHome {
      nodes {
        node_locale
        mainMenuContact {
          link
          button
          buttonBackgroundColour
          id
          textColour
          title
          target
        }
      }
    }
  }
`;

const Header = ({ white }) => {
  const [menu, setMenu] = React.useState(null);
  const { allContentfulHome } = useStaticQuery(query);
  const { locale } = useIntl();

  const { mainMenuContact } = allContentfulHome.nodes.find(
    (item) => item.node_locale === locale
  );

  const openMenu = () => {
    setMenu(true);
    document.body.classList.add("overflow-hidden");
  };

  const closeMenu = () => {
    setMenu(null);
    document.body.removeAttribute("class");
  };

  const handleClick = () => (!menu ? openMenu() : closeMenu());

  React.useEffect(() => {
    const handleKeyDown = (event) => event.key === "Escape" && closeMenu();
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <header className={`header${white ? " header-white" : ""}`}>
      <div className="header-top">
        <SocialNetworks />
        <AlertToggle />
        <Languages />
      </div>
      <div className="header-bottom">
        <Headroom pinStart={160} disableInlineStyles>
          <Link className="header-logo" href="/">
            <Logo icon />
            <Logo />
          </Link>
          <MainNav className="header-main-nav" />
          <Link className="header-contact" href={mainMenuContact.link}>
            {mainMenuContact.title}
          </Link>
          <button
            className="header-button"
            onClick={handleClick}
            aria-controls="menu"
          >
            <Hamburger />
          </button>
        </Headroom>
      </div>
      <Menu menu={menu} handleClick={handleClick} />
    </header>
  );
};

export default Header;
