import * as React from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./header";
import Footer from "./footer";
import CookieBar from "./cookie-bar";
import Alert from "./alert";

const Layout = ({ whiteHeader, alert = true, children }) => {
  React.useEffect(() => {
    AOS.init({
      offset: 56,
      duration: 500,
      once: true,
    });
  }, []);

  return (
    <>
      <CookieBar />
      {alert && <Alert />}
      <Header white={whiteHeader} />
      <main className="content">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
