import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, getSrc } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        siteUrl
      }
    }

    globalSeo: allContentfulSeo(
      filter: { contentful_id: { eq: "7p0JCj5ZZ9ySqoeviGVveS" } }
    ) {
      nodes {
        node_locale
        title
        description {
          description
        }
        image {
          description
          title
          gatsbyImageData(
            width: 1200
            height: 1200
            quality: 80
            resizingBehavior: FILL
          )
        }
      }
    }
  }
`;

const Seo = ({
  title,
  canonicalURL,
  description,
  image,
  colors,
  home,
  noIndex,
}) => {
  const data = useStaticQuery(query);
  const { locale } = useIntl();
  const { pathname } = useLocation();
  const isActiveLocale = ({ node_locale }) => node_locale === locale;
  const { defaultTitle, titleTemplate, siteUrl } = data.site.siteMetadata;
  const globalSeo = data.globalSeo.nodes.find(isActiveLocale);
  const pageUrl = canonicalURL || `${siteUrl}${pathname}`;
  const seoTitle = title || globalSeo.title;
  const seoDescription = description || globalSeo.description?.description;
  const seoImage = image || globalSeo.image;
  const seoImageData = getImage(seoImage);
  const seoImageUrl = seoImage ? `https:${getSrc(seoImage)}` : "";

  React.useEffect(() => {
    const metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (colors) metaThemeColor.setAttribute("content", colors.level);
    return () => metaThemeColor.setAttribute("content", "#c8e5c9");
  }, [colors]);

  return (
    <Helmet
      title={seoTitle}
      defaultTitle={defaultTitle}
      titleTemplate={home ? null : titleTemplate}
      htmlAttributes={{ lang: locale, dir: "ltr" }}
    >
      <link rel="canonical" href={pageUrl} />
      <meta name="description" content={seoDescription} />
      <meta property="og:site_name" content={defaultTitle} />
      <meta property="og:url" content={pageUrl} />
      <meta property="og:title" content={seoTitle || defaultTitle} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImageUrl} />
      <meta property="og:image:width" content={seoImageData?.width} />
      <meta property="og:image:height" content={seoImageData?.height} />
      <meta property="og:image:alt" content={seoImage.description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seoTitle || defaultTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImageUrl} />
      {noIndex && <meta name="robots" content="noindex" />}
      {colors && (
        <style>
          {`:root {
            --color-destination: ${colors.destination};
            --color-level: ${colors.level};
          }`}
        </style>
      )}
    </Helmet>
  );
};

export default Seo;
