import * as React from "react";
import Link from "./link";

const ButtonLink = ({
  href,
  backgroundColor,
  textColor,
  children,
  button,
  className,
  target,
  variant,
  ...props
}) => {
  const buttonStyle = {};

  if (backgroundColor) {
    buttonStyle.backgroundColor = backgroundColor;
  }

  if (textColor) {
    buttonStyle.color = textColor;
  }

  if (button === true) {
    return (
      <Link
        role="button"
        data-variant={variant}
        href={href}
        className={`${className !== undefined ? className : ""} button-link`}
        style={buttonStyle}
        target={target === true ? "_blank" : "_self"}
        {...props}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <Link
        className={`${className !== undefined ? className : ""} link`}
        target={target === true ? "_blank" : "_self"}
        href={href}
      >
        {children}
      </Link>
    );
  }
};

export default ButtonLink;
