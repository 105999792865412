import * as React from "react";

const Ultro = () => (
  <svg
    width={37}
    height={12}
    viewBox="0 0 37 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Ultrō</title>
    <path d="M35.379 0H29.229V2.1579H35.379V0Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.4338 7.93448C5.4338 8.74661 4.94335 9.2339 4.19603 9.2339C3.83796 9.2339 3.55766 9.11017 3.3475 8.85491C3.13729 8.6074 3.02835 8.29804 3.02835 7.93448V3.35569H5.72889e-05V8.47592C-0.00773587 10.1156 0.778548 11.9796 2.98159 11.9796C3.87682 11.9796 4.81102 11.5852 5.42601 10.7576H5.44939V11.8095H8.46209V3.35569H5.43385L5.4338 7.93448Z"
      fill="black"
    />
    <path d="M12.9029 0H9.8435V11.8608H12.9029V0Z" fill="black" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4189 0H15.4607L15.4521 3.40706H13.7784V5.89755H15.3742V8.80568C15.3742 9.82661 15.6778 10.6156 16.285 11.1647C16.8844 11.7216 17.6863 12 18.6905 12C19.3211 12 19.8582 11.9227 20.3019 11.7602V9.4244C19.9049 9.45532 19.6402 9.4708 19.5079 9.4708C18.7761 9.4708 18.4102 9.14596 18.4102 8.48854V5.8975H20.3798V3.40706H18.4102L18.4189 0Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.8132 3.53854C25.3383 3.70099 24.8634 4.09541 24.5443 4.62911H24.5209V3.40702H21.516V11.8608H24.552V8.38029C24.552 6.9494 25.2838 6.23014 26.7395 6.23014C26.9809 6.23014 27.23 6.25336 27.4791 6.29972L27.4869 3.38384C27.2922 3.34518 27.1054 3.32969 26.9264 3.32969C26.6072 3.32969 26.2803 3.38384 25.8132 3.53854Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.6065 4.3422C34.758 3.59197 33.5747 3.12791 32.337 3.12791C31.0992 3.12791 29.9081 3.59971 29.044 4.37318C28.1721 5.14664 27.6116 6.30679 27.6116 7.55206C27.6116 8.8205 28.1488 9.96521 28.9895 10.7309C29.8302 11.5044 31.0213 11.9685 32.2747 11.9685C33.5435 11.9685 34.7424 11.4812 35.5987 10.7C36.455 9.92655 37 8.75092 37 7.48247C37 6.23715 36.455 5.09244 35.6065 4.3422ZM33.4614 8.82122C33.1423 9.16929 32.753 9.33942 32.2938 9.33942C31.8345 9.33942 31.4374 9.16929 31.1183 8.82122C30.7991 8.48089 30.6356 8.07872 30.6356 7.61466C30.6356 7.15061 30.7913 6.74839 31.1105 6.40811C31.4296 6.07553 31.8189 5.90539 32.286 5.90539C32.753 5.90539 33.1423 6.07553 33.4614 6.40811C33.7806 6.74844 33.9363 7.15061 33.9363 7.61466C33.9363 8.07872 33.7806 8.48089 33.4614 8.82122Z"
      fill="black"
    />
  </svg>
);

export default Ultro;
