import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useIntl } from "react-intl";
import Link from "./link";

const query = graphql`
  query {
    allContentfulHome {
      nodes {
        node_locale
        mainMenu {
          id
          link
          title
          target
        }
        mainMenuContact {
          link
          button
          id
          textColour
          title
          target
        }
      }
    }
  }
`;

const MainNav = ({ className, handleClick }) => {
  const { formatMessage, locale } = useIntl();
  const { allContentfulHome } = useStaticQuery(query);
  const { mainMenu } = allContentfulHome.nodes.find(
    (item) => item.node_locale === locale
  );

  return (
    <nav className={className} aria-label={formatMessage({ id: "mainNav" })}>
      <ul // eslint-disable-line
        role="list"
      >
        {mainMenu.map((item) => {
          return (
            <li key={item.id}>
              <Link
                href={`${item.link}`}
                target={item.target === true ? "_blank" : null}
                onClick={handleClick && handleClick}
              >
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default MainNav;
