import * as React from "react";
import { useCookies } from "react-cookie";
import { FormattedMessage } from "react-intl";

const AlertToggle = ({ handleClick }) => {
  const [cookies, setCookie] = useCookies(["ALERT"]);

  const openAlert = () => {
    setCookie("ALERT", "true", { path: "/" });
    handleClick && handleClick();
  };

  if (cookies.ALERT === "true") {
    return null;
  }

  return (
    <button className="alert-toggle" onClick={openAlert}>
      <FormattedMessage id="alertHeading" />
    </button>
  );
};

export default AlertToggle;
