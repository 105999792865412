import * as React from "react";
import { FormattedMessage } from "react-intl";

const Close = () => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>
      <FormattedMessage id="close" />
    </title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.6794 1.31775L1.41747 23.7195L0.107946 22.4017L22.3699 0L23.6794 1.31775Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.30953 0.280508L23.5715 22.6822L22.262 24L0 1.59826L1.30953 0.280508Z"
      fill="currentColor"
    />
  </svg>
);

export default Close;
