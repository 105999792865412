import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { useIntl } from "react-intl";

const Link = ({ href, target, ...props }) => {
  const { locale } = useIntl();
  const path = `/${locale}${href}`;

  if (href.startsWith("/")) {
    return <GatsbyLink to={path} {...props} />;
  }
  return <a href={href} target={target} rel="noopener noreferrer" {...props} />;
};

export default Link;
